import React from 'react';
import PropTypes from 'prop-types';

import { useRenderContext } from '../../pages/home/context';
import { WITHOUT_ACTIONS_IN_ESHOPSITEM, WITH_POLYCARD } from '../../utils/constants/shop/types';
import PolycardCarousel from './PolycardCarousel';
import EshopsItemCarousel from './EshopsItemCarousel';

const ProductsCarousel = props => {
  const { tabs, view_more_action: viewMoreActionProp, isEditable, fakeCards } = props;
  const { shopType, device, siteId } = useRenderContext();
  const { items, polycard_context: polycardContext, polycards } = tabs[0] || { items: [] };
  const isPolycard = WITH_POLYCARD.includes(shopType) && polycardContext && !fakeCards;

  const seeElements = {
    action_card: !WITHOUT_ACTIONS_IN_ESHOPSITEM.includes(shopType),
  };
  const viewMoreAction = viewMoreActionProp || tabs?.[0]?.view_more_action;

  const thereAreProducts = items?.length > 0 || (polycards && polycards.length > 0);
  if (!thereAreProducts && !isEditable) {
    return null;
  }

  const isFreeCarousel = shopType !== 'super' && shopType !== 'super_partner';

  if (isPolycard) {
    return (
      <PolycardCarousel
        {...props}
        polycards={polycards}
        shopType={shopType}
        device={device}
        viewMoreAction={viewMoreAction}
        polycardContext={polycardContext}
        siteId={siteId}
        isFreeCarousel={isFreeCarousel}
      />
    );
  }

  return (
    <EshopsItemCarousel
      {...props}
      items={items}
      shopType={shopType}
      device={device}
      seeElements={seeElements}
      viewMoreAction={viewMoreAction}
      isFreeCarousel={isFreeCarousel}
      fakeCards={isEditable ? fakeCards : null}
    />
  );
};

ProductsCarousel.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    polycards: PropTypes.arrayOf(PropTypes.shape({})),
    polycard_context: PropTypes.shape({}),
    view_more_action: PropTypes.shape({}),
  })),
  view_more_action: PropTypes.shape({}),
  isEditable: PropTypes.bool,
};

ProductsCarousel.defaultProps = {
  tabs: [],
  isEditable: false,
};

export default ProductsCarousel;
