import React from 'react';
import PropTypes from 'prop-types';

import { Card, PolycardContext } from '@polycard/card';
import { SnappedCollection } from '@mshops-components-library/snapped-collection';

const PolycardCarousel = ({
  polycards,
  shopType,
  device,
  viewMoreAction,
  polycardContext,
  siteId,
  isFreeCarousel,
  namespace,
  ...props
}) => {
  const notMLM = siteId !== 'MLM';

  const formats = {
    thousandSeparator: notMLM ? '.' : ',',
    decimalSeparator: notMLM ? ',' : '.',
  };

  return (
    <div className={`${namespace} ui-ms-polycard-carousel`}>
      <PolycardContext
        contextValue={
          {
            ...polycardContext,
            type: 'grid-card',
            target: '_self',
          }
        }
        deviceType={device}
        withoutIcons
        {...formats}
      >
        <SnappedCollection
          {...props}
          eshopType={shopType}
          deviceType={device}
          view_more_action={viewMoreAction}
          isFreeCarousel={isFreeCarousel}
        >
          {
            polycards?.map((item) => (
              <Card
                key={item.metadata.id}
                polycard={item}
              />
            ))
          }
        </SnappedCollection>
      </PolycardContext>
    </div>
  );
};

PolycardCarousel.propTypes = {
  polycards: PropTypes.arrayOf(PropTypes.shape({})),
  seeElements: PropTypes.shape({}),
  shopType: PropTypes.string,
  device: PropTypes.string,
  viewMoreAction: PropTypes.shape({}),
  polycardContext: PropTypes.shape({}).isRequired,
  siteId: PropTypes.string.isRequired,
  isFreeCarousel: PropTypes.bool.isRequired,
  namespace: PropTypes.string,
};

PolycardCarousel.defaultProps = {
  polycards: [],
  seeElements: {},
  shopType: '',
  device: '',
  viewMoreAction: {},
  namespace: 'mshops-recommendations-wrapper',
};

export default PolycardCarousel;
